import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/comp.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import Wondimagegn from "../../Assets/photo_2021-05-31_10-55-51.jpg";
function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={6} className="home-header">
              <h3 style={{ paddingBottom: 15 }} className="heading">
                Hello <span className="wave" role="img" aria-labelledby="wave">👋🏻</span>
              </h3>

              <h3 className="heading-name">
                I'M
                <strong className="main-name"> Wondimagegn Zewudu</strong>
                <img style={{paddingLeft:"15%",width:"49%" }} src={Wondimagegn} alt="home pic" className="img-fluid" />
              </h3>

              <div style={{ padding: 50, textAlign: "left" }}>
                <p>Currently, I am a full-stack software engineer soaking up as much knowledge and practice as I can in an attempt to perfect my craft. I have experience using Python/Django, Javascript, SQL, Node.js, ReactJS, VueJS.
In addition to software development, I have broad interests including science education, management, science marketing, and product management, science policy, education policy, editing, and writing for both scientific and general audiences.</p>
                <Type />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img src={homeLogo} alt="home pic" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
